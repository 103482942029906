import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./style/tailwindFormat.css";
import "./utils/rem";
import util from "./utils/util";
Vue.config.productionTip = false;

Vue.prototype.$util = util;
document.body.addEventListener("touchstart", function () {});
new Vue({
	router,
	render: (h) => h(App),
}).$mount("#app");
