<template>
	<footer class="duo-footer">
		<div class="duo-content-wrap">
			<div class="footer flex flex-col">
				<div
					class="duo-footer-basic flex flex-col md:flex-row divide-y divide-black md:divide-x md:divide-y-0"
				>
					<!--logo-->
					<div>
						<img class="flex duo-footer-logo" src="../assets/logo-bottom.png" alt="" />
					</div>
					<!-- information -->
					<div class="duo-footer-info-wrap flex flex-col justify-center">
						<div class="flex flex-col md:flex-row md:justify-between">
							<div class="duo-footer-info flex flex-col items-start justify-start">
								<p>联系邮箱</p>
								<p>ad@zaofengchina.com</p>
							</div>
							<div class="duo-footer-info flex flex-col items-start justify-start">
								<p>联系电话</p>
								<p>+86 571-88685269</p>
							</div>
							<div></div>
						</div>
						<div class="duo-footer-info">
							<p>联系地址</p>
							<p>浙江省杭州市拱墅区祥园路 33 号三楼</p>
						</div>
					</div>
					<!-- qr -->
					<div class="duo-footer-qr-wrap flex-center md:justify-end">
						<div class="flex flex-col">
							<img src="../assets/official-qr-code.jpg" alt="" />
							<p>朵朵校友圈</p>
							<p>微信公众号</p>
						</div>
						<div class="flex flex-col">
							<div class="miniprogramm-qr-wrap">
								<img src="../assets/qr-code.jpg" alt="" />
							</div>
							<p>朵朵校友圈</p>
							<p>微信小程序</p>
						</div>
					</div>
				</div>
				<div class="flex flex-col divide-y divide-black">
					<div class="flex flex-col md:flex-row md:justify-center duo-footer-copyright">
						<p>
							<a href="https://static.duoduo.link/privacy.html" target="_blank">
								隐私保护指引
							</a>
						</p>
						<p>杭州筠朵网络科技有限公司 版权所有 Copyright©2024</p>
						<p>
							<a href="https://beian.miit.gov.cn/" target="_blank">
								浙 ICP 备 2023049013 号
							</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
	name: "Footer",
	data() {
		return {
			linkList: [
				{
					text: "社区规范",
				},
				{
					text: "隐私保护指引",
				},
				{
					text: "关于我们",
				},
				{
					text: "商户合作",
				},
				{
					text: "朵朵羊毛团",
				},
			],
		};
	},
};
</script>

<style scoped lang="scss">
.duo-footer {
	background-color: #181818;
	.footer {
		padding: 0 pxToRem(30);
		.duo-footer-basic {
			& > div {
				padding: pxToRem(70) 0;
			}
			.duo-footer-logo {
				width: pxToRem(375);
				height: pxToRem(88);
			}
			.duo-footer-info {
				margin-bottom: pxToRem(60);
				text-align: left;
				&:last-child {
					margin-bottom: 0;
				}
				& > p {
					&:nth-child(1) {
						font-size: pxToRem(28);
						line-height: pxToRem(42);
						color: #d9d9d9;
						font-family: SourceHanSansCN-Bold;
						font-weight: bold;
					}
					&:nth-child(2) {
						margin-top: pxToRem(8);
						font-size: pxToRem(24);
						line-height: pxToRem(38);
						color: #a6a6a6;
						font-family: SourceHanSansCN-Regular;
					}
				}
			}
			.duo-footer-qr-wrap {
				font-family: SourceHanSansCN-Regular;
				font-size: pxToRem(24);
				line-height: pxToRem(38);
				color: #a6a6a6;
				& > div:first-child {
					margin-right: pxToRem(160);
				}
				& img {
					width: pxToRem(192);
					height: pxToRem(192);
					margin-bottom: pxToRem(12);
				}
				.miniprogramm-qr-wrap {
					width: pxToRem(192);
					height: pxToRem(192);
					border-radius: 100%;
					overflow: hidden;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-bottom: pxToRem(12);
					background-color: white;
					& img {
						display: block;
						width: pxToRem(172);
						height: pxToRem(172);
						margin-bottom: 0;
					}
				}
			}
		}
		.duo-footer-link {
			color: #a6a6a6;
			font-family: SourceHanSansCN-Regular;
			font-size: pxToRem(24);
			line-height: pxToRem(38);
			margin-bottom: pxToRem(40);
			&:first-child {
				margin-top: pxToRem(60);
			}
		}
		.duo-footer-copyright {
			border-top: 1px solid #404040;
			white-space: nowrap;
			text-align: center;
			padding: pxToRem(30);
			font-size: pxToRem(20);
			line-height: pxToRem(32);
			color: #a6a6a6;
			font-family: SourceHanSansCN-Regular;
			& > p:first-child {
				color: #6c99f4;
			}
			.icon-wanganbei {
				width: 18px;
				height: 18px;
			}
		}
	}
	@include screenMedia(md) {
		.footer {
			padding: pxToRem(60) 0 0;
			.duo-footer-basic {
				& > div {
					padding: 0;
					&:nth-child(1) {
						flex-grow: 1;
					}
					&:nth-child(2) {
						flex-grow: 1.65;
					}
					&:nth-child(3) {
						flex-grow: 0.91;
					}
				}
				.duo-footer-info-wrap {
					padding: 0 pxToRem(96);
				}
				.duo-footer-info {
					margin-bottom: pxToRem(56);
					&:last-child {
						margin-bottom: 0;
					}
					& > p {
						&:nth-child(1) {
							font-size: pxToRem(18);
							line-height: pxToRem(26);
						}
						&:nth-child(2) {
							margin-top: pxToRem(6);
							font-size: pxToRem(16);
							line-height: pxToRem(24);
						}
					}
				}
				.duo-footer-qr-wrap {
					font-size: pxToRem(14);
					line-height: pxToRem(20);
					& > div:first-child {
						margin-right: pxToRem(30);
					}
					& img {
						width: pxToRem(128);
						height: pxToRem(128);
						margin-bottom: pxToRem(16);
					}
					.miniprogramm-qr-wrap {
						width: pxToRem(128);
						height: pxToRem(128);
						margin-bottom: pxToRem(16);
						& img {
							display: block;
							width: pxToRem(118);
							height: pxToRem(118);
						}
					}
				}
			}
			.duo-footer-link {
				margin-bottom: pxToRem(20);
				font-size: pxToRem(16);
				line-height: pxToRem(24);
				margin-right: pxToRem(100);
				margin-top: pxToRem(78);
				&:first-child {
					margin-top: pxToRem(78);
				}
				&:last-child {
					margin-right: 0;
				}
			}
			.duo-footer-copyright {
				font-size: pxToRem(14);
				line-height: pxToRem(20);
				margin-top: pxToRem(80);
				& > p:not(:last-child) {
					padding-right: pxToRem(14);
					margin-right: pxToRem(14);
					border-right: 1px solid #a6a6a6;
				}
			}
		}
	}
}
</style>
