<template>
	<div id="app">
		<Header></Header>
		<main>
			<router-view />
		</main>
		<Footer></Footer>
	</div>
</template>
<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
export default {
	components: { Footer, Header },
};
</script>

<style lang="scss">
@font-face {
	font-family: "SourceHanSansCN-Light";
	src: local("SourceHanSansCN-Light"),
		url("https://img.uboxs.net/SourceHanSansCN-Light-20220104.otf");
	font-display: swap;
}
@font-face {
	font-family: "SourceHanSansCN-Heavy";
	src: local("SourceHanSansCN-Heavy"),
		url("https://img.uboxs.net/SourceHanSansCN-Heavy-20220104.otf");
	font-display: swap;
}
@font-face {
	font-family: "SourceHanSansCN-Regular";
	src: local("SourceHanSansCN-Regular"),
		url("https://img.uboxs.net/SourceHanSansCN-Regular-20220104.otf");
	font-display: swap;
}
@font-face {
	font-family: "SourceHanSansCN-Medium";
	src: local("SourceHanSansCN-Medium"),
		url("https://img.uboxs.net/SourceHanSansCN-Medium-20220104.otf");
	font-display: swap;
}
@font-face {
	font-family: "SourceHanSansCN-Bold";
	src: local("SourceHanSansCN-Bold"),
		url("https://img.uboxs.net/SourceHanSansCN-Bold-20220104.otf");
	font-display: swap;
}
#app {
	font-family: "SourceHanSansCN-Regular", Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
}
:root {
	--ui-width: 750;
	--header-height: calc(100rem / 75);
	@include screenMedia(md) {
		--ui-width: 1920;
		--header-height: calc(84rem / 192);
	}
}
::-webkit-scrollbar {
	display: none;
}
.duo-content-wrap {
	width: 100%;
	box-sizing: border-box;
	position: relative;
	padding: 0 pxToRem(30);
	@include screenMedia(md) {
		padding: 0 pxToRem(210);
	}
}
main {
	padding-top: var(--header-height);
}
</style>
