<template>
	<div class="duo-content-wrap rule-container" style="text-align: left">
		<div v-for="(item, index) in rule.content" :key="index" :style="item.style">
			{{ item.text }}
		</div>
	</div>
</template>

<script>
export default {
	name: "Rules",
	data() {
		return {
			rule: {},
		};
	},
	mounted() {
		this.getRule();
	},
	methods: {
		getRule() {
			let that = this;
			let xhr = new XMLHttpRequest();
			xhr.open("GET", "https://wxapp-daidai.uboxs.com/timeline/getCommunityRule?type=rule");
			xhr.responseType = "json";
			xhr.onreadystatechange = function () {
				if (xhr.status === 200 && xhr.response !== null) {
					console.log(xhr.response.result);
					that.rule = xhr.response.result;
				}
			};
			xhr.send();
		},
	},
};
</script>

<style scoped lang="scss">
@font-face {
	font-family: "SourceHanSansCN";
	src: local("SourceHanSansCN-Bold"),
		url("https://img.uboxs.net/SourceHanSansCN-Bold-20220104.otf");
	font-display: swap;
	font-weight: bold;
}
@font-face {
	font-family: "SourceHanSansCN";
	src: local("SourceHanSansCN-Regular"),
		url("https://img.uboxs.net/SourceHanSansCN-Regular-20220104.otf");
	font-display: swap;
	font-weight: normal;
}
.rule-container {
	min-height: 100vh;
	font-family: SourceHanSansCN;
	font-size: pxToRem(24);
	padding-bottom: pxToRem(105);
	& > div:nth-child(1) {
		text-align: center;
	}
}
@include screenMedia(md) {
	.rule-container {
		font-size: pxToRem(15);
		padding-bottom: pxToRem(164);
	}
}
</style>
