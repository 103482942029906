<template>
	<div class="duo-head w-full fixed top-0 left-0">
		<div class="duo-content-wrap w-full h-full flex justify-between items-center items-center">
			<router-link class="duo-head-logo flex-shrink-0" to="/">朵朵校友圈</router-link>
			<!--			<a class="duo-head-logo flex-shrink-0" href="#head">朵朵校友圈</a>-->
			<!-- navList -->
			<div class="hidden md:flex duo-head-nav justify-between items-center w-full">
				<div class="flex">
					<template v-for="item in navList">
						<a
							class="duo-head-nav-item flex-center relative"
							:data-sub="item.subList && item.subList.length > 0 ? 1 : 0"
							:key="item.title"
							:href="item.href"
						>
							{{ item.title }}

							<template v-if="item.subList && item.subList.length > 0">
								<img class="head-nav-arrow" src="../assets/icon-head-arrow.png" />
								<div class="duo-head-sub-nav-blank hidden cursor-auto"></div>
								<ul
									class="duo-head-sub-nav hidden w-full flex flex-col"
									id="headSubNav"
								>
									<li
										v-for="sub in item.subList"
										:key="sub.title"
										class="flex-center"
									>
										<a :href="sub.href" class="sub-nav-text">{{ sub.title }}</a>
									</li>
								</ul>
							</template>
						</a>
					</template>
				</div>
				<div class="duo-head-qr cursor-pointer flex-center">
					即刻体验
					<img class="small-qr" src="../assets/icon-small-qr.png" />
					<div class="duo-head-qr-img">
						<img
							class="w-full h-full bg-black flex"
							src="../assets/qr-code.jpg"
							alt=""
						/>
					</div>
				</div>
			</div>
			<!-- toggle icon mobile-->
			<div
				class="md:hidden duo-head-toggle flex flex-col justify-between"
				:class="{ 'duo-head-toggle-active': showNav }"
				@click.stop="showNav = !showNav"
			>
				<div></div>
				<div></div>
				<div></div>
			</div>
			<!-- navList mobile -->
			<transition name="fade">
				<div
					v-if="showNav"
					class="fixed w-full h-full left-0 top-0"
					@click="showNav = false"
					@touchmove="showNav = false"
				>
					<div
						class="duo-head-nav-col flex flex-col absolute left-0 w-full divide-y divide-primary"
					>
						<div
							v-for="(item, index) in navList"
							:key="item.title"
							class="flex flex-col items-start w-full text-left"
							@click.stop="onClickNav(item, index)"
						>
							<a
								:href="item.href"
								class="w-full h-full"
								@click="item.href && (showNav = false)"
							>
								{{ item.title }}
							</a>
							<template v-if="navIndex === index">
								<div class="flex flex-col w-full">
									<div
										v-for="sub in item.subList"
										:key="sub.title"
										class="duo-head-nav-sub-item flex"
										@click.stop="onClickNav(sub, index)"
									>
										<a
											:href="sub.href"
											class="w-full h-full text-left"
											@click="showNav = false"
										>
											{{ sub.title }}
										</a>
									</div>
								</div>
							</template>
						</div>
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
export default {
	name: "Header",
	data() {
		return {
			showNav: false,
			navIndex: -1,
			navList: [
				{
					title: "产品与服务",
					subList: [
						{
							title: "校友圈",
							href: "/#xiaoyouquan",
						},
						{
							title: "攒局",
							href: "/#party",
						},
						{
							title: "羊毛团",
							href: "/#yangmaotuan",
						},
					],
				},
				{
					title: "发展历史",
					href: "/#history",
				},
				{
					title: "社区规范",
					href: "/#rule",
				},
				{
					title: "关于我们",
					subList: [
						{
							title: "产品理念",
							href: "/#product",
						},
						{
							title: "商务合作",
							href: "/#business",
						},
					],
				},
			],
		};
	},
	methods: {
		onClickNav(item, index) {
			if (item.url) {
				this.$router.push(item.url);
				return;
			}
			if (this.navIndex === index) index = -1;
			this.navIndex = index;
		},
	},
};
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
.duo-head {
	z-index: 999;
	height: var(--header-height);
	background: linear-gradient(
		to bottom,
		rgba(255, 255, 255, 0.97) 0%,
		rgba(242, 242, 242, 0.97) 100%
	);
	box-shadow: 0 pxToRem(2) pxToRem(2) 0 rgba(0, 0, 0, 0.1);
	@include screenMedia(md) {
		box-shadow: 0 pxToRem(4) pxToRem(4) 0 rgba(0, 0, 0, 0.1);
	}
	.duo-head-logo {
		overflow: hidden;
		text-indent: 120%;
		white-space: nowrap;
		width: pxToRem(271);
		height: pxToRem(52);
		background-image: url("../assets/logo-mobile.png");
		background-size: pxToRem(271), pxToRem(52);
		@include screenMedia(md) {
			width: pxToRem(228);
			height: pxToRem(52);
			background-image: url("../assets/logo.png");
			background-size: pxToRem(228), pxToRem(52);
		}
	}
	.duo-head-toggle {
		width: pxToRem(94);
		height: pxToRem(85);
		padding: pxToRem(30);
		margin-right: pxToRem(-30);
		& div {
			width: 100%;
			height: pxToRem(4);
			border-radius: pxToRem(4);
			background-color: #555555;
			transition: all 0.3s ease-in-out;
		}
		&-active {
			& div {
				&:nth-child(1) {
					transform-origin: center center;
					transform: rotate(45deg) translateY(pxToRem(8)) translateX(pxToRem(9));
				}
				&:nth-child(2) {
					opacity: 0;
				}
				&:nth-child(3) {
					transform: rotate(-45deg) translateY(pxToRem(-6)) translateX(pxToRem(6));
					transform-origin: center center;
				}
			}
		}
	}
	.duo-head-nav {
		margin-left: pxToRem(135);
		& .duo-head-nav-item {
			width: pxToRem(164);
			height: pxToRem(58);
			border-radius: pxToRem(4);
			color: #111;
			font-size: pxToRem(16);
			.head-nav-arrow {
				width: pxToRem(16);
				height: pxToRem(16);
				margin-left: pxToRem(8);
				transform-origin: center center;
				transition: transform 0.2s ease-in-out;
			}
			&:hover {
				cursor: pointer;
				background-color: #e8e8e8;
				& > div,
				& > ul {
					display: block;
				}
				.head-nav-arrow {
					transform: rotate(180deg);
				}
			}
			.duo-head-sub-nav-blank {
				position: absolute;
				top: pxToRem(58);
				width: 100%;
				height: pxToRem(30);
			}
			.duo-head-sub-nav {
				position: absolute;
				top: pxToRem(88);
				left: 0;
				border-radius: pxToRem(4);
				box-shadow: 0 1px pxToRem(4) rgba(0, 0, 0, 0.25);
				//filter: drop-shadow(0px 1px pxToRem(4) rgba(0, 0, 0, 0.25));
				background-color: #f3f3f3;
				& > li {
					font-size: pxToRem(16);
					height: pxToRem(64);
					&:nth-child(1):hover::after {
						border-bottom-color: #e8e8e8;
					}
					&:nth-child(1)::after {
						content: "";
						position: absolute;
						z-index: 1;
						width: 0;
						height: 0;
						border-left: pxToRem(12) solid transparent;
						border-right: pxToRem(12) solid transparent;
						border-top: pxToRem(8) solid transparent;
						border-bottom: pxToRem(8) solid #f3f3f3;
						left: pxToRem(70);
						top: pxToRem(-14);
					}
					&:hover {
						background-color: #e8e8e8;
					}
				}
				.sub-nav-text {
					width: 100%;
					height: 100%;
					line-height: pxToRem(64);
				}
			}
		}
		.duo-head-qr {
			position: relative;
			width: pxToRem(158);
			border-radius: pxToRem(4);
			height: pxToRem(54);
			background-color: #5d81d0;
			color: #fff;
			font-family: SourceHanSansCN-Medium;
			font-size: pxToRem(18);
			.small-qr {
				width: pxToRem(14);
				height: pxToRem(14);
				margin-left: pxToRem(8);
			}
			&:hover > .duo-head-qr-img {
				display: block;
			}
			.duo-head-qr-img {
				display: none;
				position: absolute;
				background-color: #5d81d0;
				width: pxToRem(158);
				height: pxToRem(158);
				top: pxToRem(50);
				border-radius: 0 0 pxToRem(4) pxToRem(4);
				padding: pxToRem(8);
				& > img {
					border-radius: pxToRem(4);
				}
			}
		}
	}
	.duo-head-nav-col {
		top: var(--header-height);
		background-color: #f7f7f7;
		padding: pxToRem(10) pxToRem(40);
		& > div {
			width: 100%;
			min-height: pxToRem(106);
			color: #333;
			font-size: pxToRem(30);
			padding-top: pxToRem(30);
		}
		.duo-head-nav-sub-item {
			color: #555;
			padding: pxToRem(15) 0 pxToRem(15) pxToRem(30);
			font-size: pxToRem(28);
			&:first-child {
				margin-top: pxToRem(25);
			}
			&:last-child {
				margin-bottom: pxToRem(15);
			}
		}
	}
}
</style>
