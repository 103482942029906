import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Rules from "../views/Rules";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/rules",
		name: "Rules",
		component: Rules,
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (to.path === from.path) return savedPosition;
		else return { x: 0, y: 0 };
	},
});

export default router;
