<template>
	<div class="xiaoyouquan-container w-full h-full flex">
		<div id="cards-wrap" class="cards-wrap absolute w-full" @click.stop="recoverInMobile">
			<div
				v-for="(item, index) in list"
				:key="index"
				class="out-0"
				:class="{
					'soft-hide': isSmallDevice && !item.focus && existFocusItem,
					'top-z-index': isSmallDevice && item.focus,
				}"
				:style="{
					top: pxToRem(item.top) + 'rem',
					left: pxToRem(item.left) + 'rem',
					transform: `translateX(${pxToRem(item.x)}rem) translateY(${pxToRem(
						item.y
					)}rem)`,
				}"
				@click.stop="focusInMobile(index)"
				@mouseenter="focusInPC(index)"
				@mouseleave="recoverInPC(index)"
			>
				<div class="out-1 shadow card-pop" :style="{ 'background-color': item.color }">
					<div
						class="out-2"
						:class="{ focus: item.focus }"
						:style="{
							height: isSmallDevice
								? `${pxToRem(
										item.focus ? item.focusHeight : item.defaultHeight
								  )}rem`
								: 'auto',
						}"
					>
						<div class="flex" style="align-items: flex-start">
							<img class="avatar shadow avatar-pop" :src="item.avatar" />
							<div class="flex-1 content-pop">
								<div class="nickname">{{ item.nickname }}</div>
								<div class="content">{{ item.content }}</div>
							</div>
						</div>
						<div class="tag">{{ item.tag }}</div>

						<div
							v-for="(comment, ii) in item.commentList"
							:key="ii"
							class="comment-item flex"
						>
							<img
								class="avatar avatar-small shadow avatar-pop"
								:style="{ 'animation-delay': `${ii * 0.2 + 0.4}s` }"
								:src="comment.avatar"
							/>
							<div
								class="flex-1 content-pop"
								:style="{ 'animation-delay': `${ii * 0.2 + 0.65}s` }"
							>
								<div class="nickname content-small">{{ comment.nickname }}</div>
								<div class="content content-small">{{ comment.content }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="text-container">
			<div class="title">
				全校级的大型朋友圈
				<br />
				本校内的迷你微博
			</div>
			<div class="desc">
				校友圈拥有朋友圈般和谐、互信的私域气氛。在这里你依然可以分享身边趣事，遍览校内新闻，还可以淘宝校友闲置，结识同志好友，但能量和效率却比朋友圈大上几十上百倍。
			</div>
			<div class="desc desc-2">
				校友圈提供了一个远小于全社会的舆论场，平衡了理性讨论与情感发泄的尺度，更为讨论本地公共事务，反映民生问题，提供了最初的阵地。
			</div>
		</div>
	</div>
</template>

<script>
import util from "../utils/util";

export default {
	name: "XiaoYouQuan",
	data() {
		return {
			list: [],
			lastEnterTime: 0,
			isSmallDevice: util.isSmallDevice(),
		};
	},
	computed: {
		existFocusItem() {
			return this.list.findIndex((x) => x.focus) !== -1;
		},
	},
	mounted() {
		let addList = [
			{
				top: 100,
				left: 100,
				x: 0,
				y: 0,
				focusHeight: 500,
				color: "#FDF5E6",
				disappear: false,
				duration: 6,
				nickname: "福尔魔方",
				avatar: require("../assets/avatar_random_4.png"),
				tag: "闲置交易",
				content:
					"含泪出 95 新机械键盘，Filco minila air 蓝牙无线键盘 67 键的，樱桃原厂茶轴。整体尺寸巨小！60% 键盘，便携性真没的说 ~ 原价 999 使用一个月现价 850 出，拒绝屠龙刀！",
				commentList: [
					{
						nickname: "板凳精神",
						avatar: require("../assets/avatar_random_8.png"),
						content: "我要了！楼主东西还在吗？",
					},
					{
						nickname: "兔兔可爱不吃兔兔",
						avatar: require("../assets/avatar_random_10.png"),
						content: "我是学生，免费送我，再给我塞二北~",
					},
				],
			},
			{
				top: 80,
				left: 530,
				x: 0,
				y: 0,
				focusHeight: 400,
				color: "#F8F8FF",
				disappear: false,
				duration: 5,
				nickname: "鲁班 1 号",
				avatar: require("../assets/avatar_random_14.png"),
				tag: "身边趣事",
				content:
					"兄弟，在自修教室看电影没啥大不了！人之常情嘛！学累了咱休息会！ 但是！！！大哥你看什么不好你非得看 英雄无归？！这下我接下来的将近仨小时我都得目不斜视了我。我的脖子… 再次念叨一下可恶的进口片过审 梦回复联三的漫天剧透",
				commentList: [],
			},
			{
				top: 330,
				left: 500,
				x: 0,
				y: 0,
				focusHeight: 400,
				color: "#F0F8FF",
				disappear: false,
				duration: 5,
				nickname: "我 秦始皇 打钱",
				avatar: require("../assets/avatar_random_13.png"),
				tag: "校园新闻",
				content:
					"【DFM街舞社纳新进行中！！！】\n" +
					"快闪表演高清回顾🤩美颜暴击\n" +
					"错过今天中午快闪的同学请不要遗憾\n" +
					"下午三点！DFM星秀场等你来嗨！由街舞社的【hiphop、locking、popping】舞队共同打造的精彩演出《人类高质量battle》即将上演",
				commentList: [],
			},
			{
				top: 500,
				left: 560,
				x: 0,
				y: 0,
				focusHeight: 500,
				color: "#F5FFFA",
				disappear: false,
				duration: 6,
				nickname: "芝士蛋塔",
				avatar: require("../assets/avatar_random_17.png"),
				tag: "学业求助",
				content:
					"求助朵友，python 怎么循环输入，就是输入一个数 x，再输入 x 个数，然后第一个是换行，后面的 x 个数用空格间隔；以及 python 的数组好奇怪，用过 Pascal 感觉 python 的语言体系就很难接受改过来",
				commentList: [
					{
						nickname: "奥黛丽赫本",
						avatar: require("../assets/avatar_random_18.png"),
						content: "只需要这样，再那样，然后这样，最后如此这般就好了",
					},
				],
			},
			{
				top: 600,
				left: 120,
				x: 0,
				y: 0,
				focusHeight: 400,
				color: "#FFF5EE",
				disappear: false,
				duration: 5,
				nickname: "抱着落日",
				avatar: require("../assets/avatar_random_3.png"),
				tag: "兼职招聘",
				content:
					"【六年级英语家教老师招聘】男生，家教辅导。需要英语老师一名。要求：英语系在校生，竞赛获奖者优先，主要教授英语课本及新概念等课外辅导资料。时间：每周一次，时间可商量，时长二小时，薪资 200/小时，合计 400 一次",
				commentList: [],
			},
			{
				top: 340,
				left: 140,
				x: 0,
				y: 0,
				focusHeight: 450,
				color: "#FFFAFA",
				disappear: false,
				duration: 5,
				nickname: "土豆土豆土",
				avatar: require("../assets/avatar_random_20.png"),
				tag: "树洞吐槽",
				content:
					"我麻了，我好像真的很擅长摸鱼\n" +
					"上课摸鱼，下课摸鱼，熬夜摸鱼，早起摸鱼，整天摸鱼……\n" +
					"我想不明白，我真的想不明白 \n" +
					"我现在就是后悔，为什么高考要报浙大，要来浙里被卷\n" +
					"我甚至开始思考我的就业\n" +
					"我应该去渔场工作，然后摸鱼摸到爽",
				commentList: [],
			},
		];
		let initPosInMobile = [
			{
				top: 0,
				left: 50,
			},
			{
				top: 140,
				left: 160,
			},
			{
				top: 250,
				left: 40,
			},
			{
				top: 370,
				left: 140,
			},
			{
				top: 480,
				left: 40,
			},
			{
				top: 580,
				left: 140,
			},
		];
		let hasShow = false;

		let showOne = (idx) => {
			hasShow = true;
			if (idx >= addList.length) return;
			let obj = JSON.parse(JSON.stringify(addList[idx]));
			if (this.isSmallDevice) {
				obj.top = initPosInMobile[idx].top;
				obj.left = initPosInMobile[idx].left;
			} else {
				obj.top += Math.min(window.innerHeight * 1.1, window.innerWidth * 0.52) * 0.05;
			}
			obj.defaultHeight = 200;
			obj.focus = false;
			this.list.push(obj);
			setTimeout(() => {
				obj.disappear = true;
			}, 5 * 1000);
			setTimeout(() => {
				showOne(idx + 1);
			}, 1.2 * 100);
		};

		setTimeout(() => {
			this.observer = new IntersectionObserver(
				(entries) => {
					let show = entries[0].isIntersecting;
					if (show && !hasShow) {
						showOne(0);
					}
				},
				{
					threshold: 0.8,
				}
			);
			this.observer.observe(document.querySelector("#cards-wrap"));
		}, 50);
	},
	methods: {
		pxToRem(px) {
			return (px * 10) / util.getBaseWidth();
		},
		focusInMobile(index) {
			if (!this.isSmallDevice) {
				return;
			}
			let target = this.list[index];
			if (target.focus || this.existFocusItem) {
				this.recoverInMobile();
				return;
			}
			this.recoverInMobile();
			this.list[index].x = -target.left + 100;
			this.list[index].y = -target.top + 50;
			this.list[index].focus = true;
		},
		recoverInMobile() {
			for (let i = 0; i < this.list.length; i++) {
				this.list[i].x = 0;
				this.list[i].y = 0;
				this.list[i].focus = false;
			}
		},
		focusInPC(index) {
			if (this.isSmallDevice) {
				return;
			}

			let focusMove = {
				0: {
					1: {
						x: 40,
						y: 0,
					},
					2: {
						x: 70,
						y: 20,
					},
					5: {
						x: 10,
						y: 135,
					},
				},
				1: {
					2: {
						x: -25,
						y: 35,
					},
					5: {
						x: -10,
						y: 35,
					},
				},
				2: {
					3: {
						x: 40,
						y: 120,
					},
					4: {
						x: -20,
						y: 20,
					},
					5: {
						x: -110,
						y: 20,
					},
				},
				3: {
					4: {
						x: -35,
						y: 0,
					},
					5: {
						x: -50,
						y: -10,
					},
				},
				4: {
					5: {
						x: 10,
						y: -55,
					},
				},
			};

			if (index === 5 && this.list[0].focus && Date.now() - this.lastEnterTime < 150) {
				return;
			}
			this.lastEnterTime = Date.now();

			if (this.list[index].focus) {
				return;
			}
			for (let i = 0; i < this.list.length; i++) {
				this.list[i].focus = i === index;
				if (i <= index) {
					this.list[i].x = 0;
					this.list[i].y = 0;
				} else {
					if (focusMove[index] && focusMove[index][i]) {
						this.list[i].x = focusMove[index][i].x;
						this.list[i].y = focusMove[index][i].y;
					} else {
						this.list[i].x = 0;
						this.list[i].y = 0;
					}
				}
			}
		},
		recoverInPC(index) {
			if (this.isSmallDevice) {
				return;
			}

			if (index === 5 && Date.now() - this.lastEnterTime < 150) {
				return;
			}
			for (let i = 0; i < this.list.length; i++) {
				this.list[i].x = 0;
				this.list[i].y = 0;
				this.list[i].focus = false;
			}
		},
	},
};
</script>

<style scoped lang="scss">
.shadow {
	box-shadow: 0 0 pxToRem(40) 0 rgba(0, 0, 0, 0.12);
}

.flex {
	display: flex;
	justify-content: center;
	align-items: center;
}

.flex-1 {
	flex: 1;
}

.xiaoyouquan-container {
	text-align: left;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

.cards-wrap {
	position: absolute;
	width: 100%;
	height: pxToRem(800);
	top: pxToRem(650);
	left: 0;
}

.out-0 {
	position: absolute;
	transition: all 0.4s cubic-bezier(0.145, -0.0025, 0, 1.19);
	user-select: none;
	border-radius: pxToRem(20);
	box-sizing: border-box;

	&.soft-hide {
		opacity: 0.2;
	}

	&.top-z-index {
		z-index: 50;
	}
}

.out-1 {
	width: pxToRem(550);
	border-radius: pxToRem(20);
	overflow: hidden;
	background-color: #fdf5e6;
	position: relative;
	transition: box-shadow 0.25s ease-out;
	box-sizing: border-box;
}

.out-2 {
	width: 100%;
	height: pxToRem(200);
	padding: pxToRem(24);
	box-sizing: border-box;
	transition: height 0.4s ease-out;
	&.focus {
		height: pxToRem(500);
	}
}

.avatar {
	width: pxToRem(70);
	height: pxToRem(70);
	display: block;
	background-color: gray;
	border-radius: 100%;
	margin-right: pxToRem(16);
}

.nickname {
	margin-bottom: pxToRem(6);
	color: #6495ed;
	font-size: pxToRem(26);
}

.content {
	letter-spacing: pxToRem(1);
	font-size: pxToRem(26);
	font-weight: 300;
	color: #555555;
	white-space: pre-line;
}

@keyframes card-pop {
	from {
		opacity: 0;
		transform: scale(0.2);
	}
	to {
		opacity: 1;
		transform: scale(1);
	}
}

.card-pop {
	animation: card-pop 0.5s cubic-bezier(0.145, -0.0025, 0, 1.19) both;
}

@keyframes avatar-pop {
	from {
		opacity: 0;
		transform: scale(0.2);
	}
	to {
		opacity: 1;
		transform: scale(1);
	}
}

.avatar-pop {
	animation: avatar-pop 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0.4s both;
	transform-origin: center center;
}

@keyframes content-pop {
	from {
		opacity: 0;
		transform: scale(0.2);
	}
	to {
		opacity: 1;
		transform: scale(1);
	}
}

.content-pop {
	animation: content-pop 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0.65s both;
	transform-origin: left top;
}

.avatar-small {
	width: pxToRem(50);
	height: pxToRem(50);
	margin-left: pxToRem(20);
}

.content-small {
	font-size: pxToRem(22);
}

.comment-item {
	margin-top: pxToRem(16);
	align-items: flex-start;
}

.tag {
	position: absolute;
	top: pxToRem(-16);
	right: 0;
	font-size: pxToRem(45);
	font-weight: 900;
	color: rgba(0, 0, 0, 0.15);
}

.text-container {
	width: 100%;
	padding: pxToRem(187) pxToRem(30) 0;
	text-align: center;
	box-sizing: border-box;
}

.title {
	font-size: pxToRem(60);
	line-height: pxToRem(87);
	margin-bottom: pxToRem(30);
	font-family: SourceHanSansCN-Heavy;
	//font-weight: bold;
}

.desc {
	font-size: pxToRem(32);
	line-height: pxToRem(50);
	color: #555;
	font-family: SourceHanSansCN-Regular;

	&.desc-2 {
		padding-top: pxToRem(930);
		padding-bottom: pxToRem(100);
	}
}

@include screenMedia(md) {
	.xiaoyouquan-container {
		flex-direction: row;
		justify-content: flex-end;
	}

	.cards-wrap {
		height: 100%;
		top: 0;
	}

	.out-0 {
		transition: all 0.2s ease-out;
	}

	.out-1 {
		width: pxToRem(450);
	}

	.out-2 {
		height: auto;
		&.focus {
			height: auto;
		}
	}

	.nickname {
		font-size: pxToRem(18);
	}

	.content {
		font-size: pxToRem(18);
	}

	.content-small {
		font-size: pxToRem(16);
	}

	.text-container {
		width: pxToRem(800);
		padding: 0 pxToRem(100) 0 0;
		text-align: left;
	}

	.title {
	}

	.desc {
		font-size: pxToRem(28);
		&.desc-2 {
			padding-top: pxToRem(24);
			padding-bottom: 0;
		}
	}
}
</style>
