<template>
	<div class="home">
		<section class="head">
			<a class="anchor" name="head"></a>
			<div class="absolute w-full top-0 left-0">
				<img class="duo-btn-icon" src="../assets/icon_like.png" />
				<img class="duo-btn-icon" src="../assets/icon_message.png" />
				<img class="duo-btn-icon" src="../assets/icon_reply.png" />
				<img class="duo-btn-icon" src="../assets/icon_party.png" />
			</div>
			<div class="duo-content-wrap flex">
				<div class="duo-c-body flex flex-col">
					<p>连接每一个大学生</p>
					<p>做最好的校内互动平台</p>
					<p>
						聚焦于以每个学校为中心的本地用户，争取成为每个在校大学生必备的本地生活助手
					</p>
					<div
						class="duo-qr-btn flex-center cursor-pointer"
						:class="{ active: duoQrActive }"
						@click="toMiniProgram"
					>
						即刻体验
						<img class="duo-qr-btn-arrow" src="../assets/icon-white-arrow.png" />
						<div class="duo-qr">
							<div
								class="qr-wrap flex justify-center items-center w-full h-full bg-white"
							>
								<img class="qr-code" src="../assets/qr-code.jpg" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="duo-phone-wrap">
				<div class="video-wrap">
					<video
						v-if="!isMobile"
						class="video"
						src="https://img.uboxs.net/VycODw6X0tyfJTiXZaC9Usnj2bVffIFruiH2EZKK.mp4"
						autoplay
						muted
						loop
					/>
				</div>
				<img class="phone-circle" src="../assets/phone-circle.png" />
				<img class="duo-emoji emoji-1" src="../assets/icon_huaji.png" />
				<img class="duo-emoji emoji-2" src="../assets/icon_wulian.png" />
				<img class="duo-emoji emoji-3" src="../assets/icon_xiaoku.png" />
			</div>
		</section>

		<section class="xiaoyouquan">
			<a class="anchor" name="xiaoyouquan"></a>
			<h2 class="duo-xiaoyouquan-title">校友圈</h2>
			<XiaoYouQuan></XiaoYouQuan>
		</section>

		<section class="party relative overflow-hidden">
			<a class="anchor" name="party"></a>
			<h2 class="duo-party-title">攒局</h2>
			<div ref="partyIntro" class="duo-content-wrap duo-party-intro flex flex-col relative">
				<p>
					线下认识真实的校友
					<br />
					然后成为朋友
				</p>
				<p>
					攒局功能以精心设计的安全机制，提供了私密结识的途径和安心参与的气氛，在切实解决问题的线下活动中，帮助校友结识新友谊，胜过虚拟网络上的千言万语。
				</p>
				<div v-for="item in 5" :key="item" class="duo-party-marker absolute"></div>
			</div>
			<div ref="partyMore" class="duo-content-wrap duo-party-more flex flex-col relative">
				<div class="flex flex-col md:flex-row md:justify-center">
					<h2>以兴趣与活动为支点</h2>
					<h2>触摸互联网的真实</h2>
				</div>
				<p>
					一起运动，一起自习，一起吃饭，一起旅行…
					<br />
					一次次的校内破冰，在驱赶孤单的同时，助你 淘洗出属于你志同道合的真朋友。
				</p>
				<div class="duo-party-more-banner flex flex-col">
					<template v-for="(item, index) in partyMoreList">
						<div
							:key="index"
							class="duo-party-more-banner-wrap overflow-hidden w-full relative"
						>
							<div
								class="duo-party-more-banner-box flex absolute left-0 top-0 h-full"
							>
								<div
									v-for="(moreItem, moreIndex) in item"
									:key="moreIndex"
									class="flex items-center duo-party-more-banner-item"
									:style="{
										background: `linear-gradient(to right, ${moreItem.color} 3%, #fffbf3 3%)`,
									}"
								>
									<template v-for="(avatar, avatarIndex) in 4">
										<div
											:key="avatarIndex"
											:ref="'avatar-' + avatarIndex"
											:data-avatar="moreItem.avatar"
											:style="{
												zIndex: 4 - avatarIndex,
											}"
										></div>
									</template>

									<p>{{ moreItem.text }}</p>
								</div>
							</div>
						</div>
					</template>
				</div>
			</div>
		</section>
		<section class="yangmaotuan relative">
			<a class="anchor" name="yangmaotuan"></a>
		</section>
		<section class="history relative">
			<a class="anchor" name="history"></a>
			<h2 class="duo-history-title">发展历史</h2>
			<div class="duo-content-wrap duo-history-wrap">
				<div class="duo-history-container flex flex-col">
					<div v-for="item in historyList" :key="item.state" class="duo-history-item">
						<div class="duo-history-time flex flex-col">
							<div class="flex flex-col md:flex-row">
								<i>{{ item.time.year }}</i>
								<i v-if="item.time.date">{{ item.time.date }}</i>
							</div>
							<div>{{ item.state }}</div>
						</div>
						<h3>{{ item.title }}</h3>
						<p class="duo-history-detail">{{ item.detail }}</p>
						<div v-if="item.images.length > 0" class="duo-history-pic flex">
							<div
								v-for="(image, imageIndex) in item.images"
								:key="imageIndex"
								@click.stop="previewImage(image.src)"
								class="cursor-pointer flex-shrink-0"
							>
								<img
									ref="duoPreviewImage"
									:data-size="`${image.width}x${image.height}`"
									:src="image.src"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="rule relative">
			<a class="anchor" name="rule"></a>
			<div class="flex flex-col md:flex-row-reverse">
				<div class="duo-rule-info overflow-hidden relative flex justify-center">
					<p class="duo-rule-title">社区规范</p>
					<div class="duo-rule-text flex flex-col flex-wrap">
						<p>
							朵朵校友圈致力于为用户提供一个温暖、可信、友善、和谐的线上社区。为了实现这个目标，在长期的运营过程中，我们根据自身价值判断与社区风格逐渐制定、动态完善出了朵朵校友圈社区规范，作为我们日常运维社区的依据。社区规范是朵朵这个大家庭内所有成员的共识，是大家信奉并遵守的法律与道德准则，是社区健康运转与成长的基石。
						</p>
						<router-link class="cursor-pointer" to="/rules">
							进一步了解社区规范细则 >
						</router-link>
						<!--						<a-->
						<!--							class="cursor-pointer"-->
						<!--							href="https://static.duoduo.link/rule.html"-->
						<!--							target="_blank"-->
						<!--						>-->
						<!--							进一步了解社区规范细则 >-->
						<!--						</a>-->
					</div>
				</div>
				<div class="duo-rule-slogan">
					<div class="flex md:flex-col">
						<h2>温暖</h2>
						<h2>友善</h2>
					</div>
					<div class="flex md:flex-col">
						<h2>可信</h2>
						<h2 class="relative">和谐</h2>
					</div>
				</div>
			</div>
		</section>
		<section class="product relative">
			<a class="anchor" name="product"></a>
			<div
				class="duo-content-wrap duo-product relative overflow-hidden flex flex-col items-center"
			>
				<h2 class="duo-product-title">产品理念</h2>
				<p class="duo-product-intro">
					朵朵校友圈是我们团队倾注了心血的产品，虽限于视野、人力、资金的不足，始终难臻完美，但她的每一个设计，每一处取舍，每一次调整，都是我们产品观与价值观的体现。客服后台经常有用户向我们发起关于产品定位、产品设计、发展方向、价值主张、产品愿景相关的提问，此处以FAQ的形式做一次集中的整理：
				</p>
				<h3>FAQ</h3>
				<template v-for="(item, index) in FAQList">
					<div v-if="index <= 2 || showAllFAQ" :key="item.q" class="duo-product-card">
						<h3>{{ `Q${index + 1}：${item.q}` }}</h3>
						<p>{{ `A${index + 1}：${item.a}` }}</p>
					</div>
				</template>

				<div
					v-if="!showAllFAQ"
					class="duo-product-btn flex-center cursor-pointer"
					@click.stop="showAllFAQ = true"
				>
					查看全部 FAQ
				</div>
			</div>
		</section>
		<section class="business bg-white relative">
			<a class="anchor" name="business"></a>
			<div class="duo-content-wrap duo-business flex flex-col items-center">
				<div class="flex flex-col w-full items-center">
					<h2>商务合作</h2>
					<div class="duo-business-card flex justify-between items-center">
						<div class="flex flex-col text-left">
							<div class="duo-business-card-name">朵朵羊毛团小羊</div>
							<!-- <div class="duo-business-card-info">电话：+86 17764517849</div> -->
							<div class="duo-business-card-info">微信：ddymtbd</div>
						</div>
						<div class="duo-business-qr">
							<img class="w-full h-full" src="../assets/business_qr_code.jpg" />
						</div>
					</div>
					<h2>合作品牌</h2>
					<div class="duo-business-cooperation-pc">
						<div class="duo-business-cooperation-pc-line flex-center">
							<img src="../assets/logo_mt.png" />
							<img src="../assets/logo_mhy.png" />
							<img src="../assets/logo_tclx.png" />
							<img src="../assets/logo_ubras.png" />
							<img src="../assets/logo_wph.png" />
						</div>
						<div class="duo-business-cooperation-pc-line flex-center">
							<img src="../assets/logo_nyyh.png" />
							<img src="../assets/logo_dianxin.png" />
							<img src="../assets/logo_yidong.png" />
							<img src="../assets/logo_jsyh.png" />
						</div>
						<div class="duo-business-cooperation-pc-line flex-center">
							<img src="../assets/logo_meiwei.png" />
							<img src="../assets/logo_lhtj.png" />
							<img src="../assets/logo_qicai.png" />
							<img src="../assets/logo_yadi.png" />
							<img src="../assets/logo_hzkq.png" />
						</div>
						<div class="duo-business-cooperation-pc-line flex-center">
							<img src="../assets/logo_tengxun.png" />
							<img src="../assets/logo_fangci.png" />
							<img src="../assets/logo_xianyu.png" />
							<img src="../assets/logo_cainiao.png" />
						</div>
						<div class="duo-business-cooperation-pc-line flex-center">
							<img src="../assets/logo_xinhangdao.png" />
							<img src="../assets/logo_xindongfang.png" />
							<img src="../assets/logo_ACG.png" />
							<img src="../assets/logo_xintong.png" />
							<img src="../assets/logo_huatu.png" />
						</div>
					</div>
					<div class="duo-business-cooperation-mobile">
						<div class="duo-business-cooperation-mobile-line flex-center">
							<img src="../assets/logo_mt.png" />
							<img src="../assets/logo_mhy.png" />
							<img src="../assets/logo_tclx.png" />
						</div>
						<div class="duo-business-cooperation-mobile-line flex-center">
							<img src="../assets/logo_nyyh.png" />
							<img src="../assets/logo_wph.png" />
							<img src="../assets/logo_tengxun.png" />
						</div>
						<div class="duo-business-cooperation-mobile-line flex-center">
							<img src="../assets/logo_ubras.png" />
							<img src="../assets/logo_xianyu.png" />
							<img src="../assets/logo_cainiao.png" />
						</div>
						<div class="duo-business-cooperation-mobile-line flex-center">
							<img src="../assets/logo_meiwei.png" />
							<img src="../assets/logo_qicai.png" />
							<img src="../assets/logo_yadi.png" />
						</div>
						<div class="duo-business-cooperation-mobile-line flex-center">
							<img src="../assets/logo_xinhangdao.png" />
							<img src="../assets/logo_xindongfang.png" />
							<img src="../assets/logo_xintong.png" />
						</div>
						<div class="duo-business-cooperation-mobile-line flex-center">
							<img src="../assets/logo_huatu.png" />
							<img src="../assets/logo_ACG.png" />
							<img src="../assets/logo_hzkq.png" />
						</div>
						<div class="duo-business-cooperation-mobile-line flex-center">
							<img src="../assets/logo_dianxin.png" />
							<img src="../assets/logo_yidong.png" />
							<img src="../assets/logo_lhtj.png" />
						</div>
					</div>
				</div>
			</div>
		</section>

		<!-- Root element of PhotoSwipe. Must have class pswp. -->
		<div ref="pswp" class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
			<!-- Background of PhotoSwipe.
				 It's a separate element as animating opacity is faster than rgba(). -->
			<div class="pswp__bg"></div>

			<!-- Slides wrapper with overflow:hidden. -->
			<div class="pswp__scroll-wrap">
				<!-- Container that holds slides.
					PhotoSwipe keeps only 3 of them in the DOM to save memory.
					Don't modify these 3 pswp__item elements, data is added later on. -->
				<div class="pswp__container">
					<div class="pswp__item"></div>
					<div class="pswp__item"></div>
					<div class="pswp__item"></div>
				</div>

				<!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
				<div class="pswp__ui pswp__ui--hidden">
					<div class="pswp__top-bar" style="display: none">
						<!--  Controls are self-explanatory. Order can be changed. -->

						<div class="pswp__counter"></div>

						<button
							class="pswp__button pswp__button--close"
							title="Close (Esc)"
						></button>

						<button class="pswp__button pswp__button--share" title="Share"></button>

						<button
							class="pswp__button pswp__button--fs"
							title="Toggle fullscreen"
						></button>

						<button
							class="pswp__button pswp__button--zoom"
							title="Zoom in/out"
						></button>

						<!-- Preloader demo https://codepen.io/dimsemenov/pen/yyBWoR -->
						<!-- element will get class pswp__preloader--active when preloader is running -->
						<div class="pswp__preloader">
							<div class="pswp__preloader__icn">
								<div class="pswp__preloader__cut">
									<div class="pswp__preloader__donut"></div>
								</div>
							</div>
						</div>
					</div>

					<div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
						<div class="pswp__share-tooltip"></div>
					</div>

					<button
						class="pswp__button pswp__button--arrow--left"
						title="Previous (arrow left)"
					></button>

					<button
						class="pswp__button pswp__button--arrow--right"
						title="Next (arrow right)"
					></button>

					<div class="pswp__caption">
						<div class="pswp__caption__center"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PhotoSwipe from "photoswipe/dist/photoswipe";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";
import util from "../utils/util";
import XiaoYouQuan from "../components/XiaoYouQuan";
export default {
	name: "Home",
	components: { XiaoYouQuan },
	data() {
		return {
			partyMoreList: [],
			isMobile: util.isMobile(),
			duoQrActive: false,
			historyList: [
				{
					time: {
						year: 2015,
						date: "10.11",
					},
					state: "孵化阶段",
					title: "浙大云朵朵个人微信号发布第一条朋友圈，标志着云朵朵的诞生",
					detail: "云朵朵是本团队第一个创业项目云格子铺发展中期的内部孵化项目，旨在以公益互助平台的身份协助云格子铺做好客服与纳新工作，由当时团队内实习生运维。因其独特的人格魅力和新鲜便捷的使用体验，在浙大同学中大受欢迎，团队便在浙大城市学院与中国美术学院相继开通云朵朵个人账号，各自独立运营。",
					images: [
						{
							src: "https://img.uboxs.net/1zfTORsKakDpXDR1zg1UDgZaggReR3E0KTZ3z1TF.png",
							w: 1252,
							h: 2496,
						},
					],
				},
				{
					time: {
						year: 2018,
						date: "07.27",
					},
					state: "转型阶段",
					title: "朵朵校友圈小程序完成内测，正式上线",
					detail: "虽然不自觉成为所谓私域流量领域的先行者，但非产品化的机械式运营并非健康可持续的，团队在危机感中，于 2017 年 8 月开始筹备开发云朵朵小程序，并在当年底完成了 1.0 版本的开发工作，然后在朵友间开展小范围内测，后克服种种资质障碍和意外坎坷，终于次年暑假上线小程序，定名为朵朵校友圈，与云朵朵个人号协同使用。",
					images: [
						{
							src: "https://img.uboxs.net/2cpxcR1k1b58XS13n7hLxxKlQqFTMbETK4iC8M1z.png",
							w: 1252,
							h: 2496,
						},
					],
				},
				{
					time: {
						year: 2019,
						date: "",
					},
					state: "发展阶段",
					title: "朵朵校友圈小程序逐渐迭代，云朵朵个人号下线",
					detail: "这一年，我们为朵朵校友圈陆续添加了夜间阅后即焚的匿名模式（呆呆校友圈）、私聊功能、攒局等功能…持续打磨完善产品，逐渐成为校园社区类小程序的业界标杆，引得跟风模仿者众多。随着产品的成熟完善，我们停止了云朵朵个人号的日常更新，开始将朵朵校友圈由孵化项目转为团队的正式项目，倾注了更多开发与运维资源，以保证朵朵发展之路走得更加稳健长远。",
					images: [
						{
							src: "https://img.uboxs.net/3Gi0qFvBfbQwlETlnAP6NSNn1WmGp9e0c8LVoZdy.png",
							w: 1252,
							h: 2496,
						},
						{
							src: "https://img.uboxs.net/4JshfeLPO5vwC2UjePKOW07IiwQtGmBcs9rBeom5.png",
							w: 1252,
							h: 2496,
						},
					],
				},
				{
					time: {
						year: 2020,
						date: "04.07",
					},
					state: "开放阶段",
					title: "朵朵校友圈开放众筹与合伙人模式，覆盖全杭州",
					detail: "从云朵朵个人号风靡浙大，到朵朵校友圈因为各种热点事件小范围出圈，我们的客服后台不断收到来自全国各地多个高校的热心同学甚至老师的问询，他们或好奇我们的运营模式，或急切地盼望我们把校友圈开过去，或希望能够依托我们的经验与技术协助他们创建自己的校友圈…我们既感到自豪，又深觉压力重大，最终决定于 2020 年疫情稍缓时，依托众筹开校与合伙人运维的“开源”模式，在杭州市场迅速扩张，由 3 所自营院校拓展到了横跨全杭的 20 余所重点院校。",
					images: [
						{
							src: "https://img.uboxs.net/5oE0QjyXNO912bU36qcwVgKDegSsgwShc1BougpO.png",
							w: 1252,
							h: 2496,
						},
						{
							src: "https://img.uboxs.net/6IkoxOMVl8GmqPp8jdlaQPsfnOJzHWFmgCHhCUbq.png",
							w: 1252,
							h: 2496,
						},
					],
				},
				{
					time: {
						year: 2021,
						date: "01.20",
					},
					state: "商业探索",
					title: "朵朵羊毛团上线，承载吃喝玩乐等本地生活服务",
					detail: "为了在最受用户欢迎的吃喝玩乐活动上充分挖掘价值，我们在调研走访了诸多用户和线下餐饮、娱乐与生活服务类商家后，推出了汇集校园周边生活服务优惠套餐与折扣券的朵朵羊毛团。旨在以良好的包装、通达的渠道链接商家，充分挖掘闲时流量的价值。同时，接入拼多多与京东的折扣返利电商，在经过精心的选品与严苛的筛选后，与美团、饿了么的外卖导流红包一起，组成了朵朵在校园线上线下生活电商服务上的完整拼图。",
					images: [
						{
							src: "https://img.uboxs.net/460a140502544d6e99497169fa65b811.png",
							w: 791,
							h: 1596,
						},
						{
							src: "https://img.uboxs.net/8714379b94824a48b71adb0e86941f5f.png",
							w: 791,
							h: 1608,
						},
					],
				},
				{
					time: {
						year: 2022,
						date: "12.14",
					},
					state: "迈向未来",
					title: "率先上线 GPT-3 助手@呆呆学长，全面迈向 AIGC 时代",
					detail: "有感于生成式语言模型的突飞猛进，结合实际的业务需求，我们在国内率先采用 OpenAI 的 GPT-3 大型语言模型，在使用校友圈内庞大的本地数据对模型 fine-tuning 后，迅速落地产品，以 @呆呆学长 社区 bot 的形式直面用户，赢得了用户巨大的使用热情，提升了社区的互动度和活跃度，并显著提高了社区内容生产、过滤、推荐的效率，进一步降低了我们的运营成本。",
					images: [
						{
							src: "https://img.uboxs.net/J92WOowtdZgYhjXDunzB8r4EJIfIZIyWRMNxyRnL.png",
							w: 800,
							h: 1614,
						},
						{
							src: "https://img.uboxs.net/nOX9io0NWTEx1vKAwaLIO3tksGJUlPIXVaihqpKl.png",
							w: 800,
							h: 1614,
						},
					],
				},
			],
			FAQList: [
				{
					q: "为什么叫朵朵校友圈？",
					a: "这个要拆开来讲。开头叫“朵朵”，是因为我们团队最开始的创业产品叫云格子铺——一个在烧钱大战中已经壮烈牺牲的闲置交易平台先驱，我们为它创建的微信客服号，为了给人亲和的感觉，并兼顾“云”家族的概念，便起名叫云朵朵（毕竟听起来就很软萌不是么）。后面做成小程序的时候，为了方便传播，就选择了大家常喊的简称“朵朵”。至于校友圈，最主要是因为我们觉得它听起来很直观，且和我们不止关注在校学生需求的愿景不谋而合。",
				},
				{
					q: "有什么东西启发或激励过你们吗？",
					a: "传统 BBS、贴吧和人人网都是我们少年时的美好回忆，所以我们也想延续曾经的那份美好，而我本人又很欣赏 Reddit 和豆瓣的产品，把它们当做努力的方向。在做产品的过程中，发现很多想法和 Dcard 与 Plurk 这样小有成就的产品不谋而合，又得到了不少慰藉。总而言之，朵朵产品，是以解决运营过程中实际遇到的问题为前提，结合我们团队多年网上冲浪淘洗下的经验和自身价值取向，在思考与推敲中，精心雕琢而成的。",
				},
				{
					q: "那你们做朵朵的价值取向是什么？",
					a: "儿时看过一部香港电影《新难兄难弟》，里面老好人梁家辉坚守的“我为人人，人人为我”对我触动很大。所以我们想延伸“互助”这一概念，打造一个“温暖、可信、友善、和谐”的社区，我们也相信这是不同于以往很多社区的形象的，是很多用户一定会真心喜欢的社区。",
				},
				{
					q: "那你们是怎样实践你们的价值观的呢？",
					a: "我们坚信，这样一个理想社区，存在的前提是有序。所以我们根据目标用户和市场的特点，结合多年的运营经验，动态制定出了我们的《社区规范》，在一些人机交互的地方设定了很多引导，并尽力去监管规范的施行。同时，在身份保障上，我们采取证件验证与答题验证双保险模式，兼顾内外平衡；在社区内容与风气上，我们提倡平等、去中心化的创作空间与互动方式；在角色站位上，我们坚持独立自主，学生优先的原则。",
				},
				{
					q: "在这个实践的过程中你们有什么得失？",
					a: "我们在每个阶段都做出了自己喜欢的产品，并得到绝大多数用户认可，这是我们最大的收获。当然，在维持社区秩序和风格的过程中，我们不断在对抗人性的弱点。自私、误解、忌恨、鲁莽、歧视、臆测、嘲笑、中伤…我们见识并经历过太多。好在我们依然深信有更多的人理解并默默支持着我们，并以此作为坚持的动力。",
				},
				{
					q: "你们如何看待所谓朵朵从校园公益助手向商业社交平台的转变？",
					a: "朵朵的公益属性并没有改变，这也是我们做产品的初心。无论是个人号的校园助手，还是平台化的信息集散地，朵朵始终都是以服务公众利益而存在的。只是公益并不等于慈善。我们尚没有零商业化持续运营该项目的能力。在拒绝商业化朵朵项目近 5 年后，我们不得不重新思考产品健康生存与稳步发展的路径，学习以良性的商业化步调来助力产品成长。事实上，到目前为止，即使我们竭力削减成本，控制支出，朵朵校友圈仍然是一个靠团队自身输血才能生存的另类项目，说是“用爱发电”也不为过。",
				},
				{
					q: "你们最喜欢朵朵校友圈里的哪个功能？",
					a: "别人我不太清楚，我个人最喜欢的是“马住”功能（或许今天应该叫“轻置玉臀”？）。其实它就是一个追踪帖子的功能，脱胎于剥离了点赞本身功能的一个朋友圈特性。实际上很后来我们才知道其他产品有类似的“插眼”功能。在这之前，我们希望用户可以安静地把自身联结到每一个感兴趣的内容下面，并跟进它的后续发展。它比收藏要轻得多，即时的反馈可以让用户实时感受其他人思绪的流动。毕竟我们一直相信每个人都会对自己周遭 1 km² 内正在发生的事情感兴趣，也很想知道和自己相同处境的人天天都在想什么。",
				},
				{
					q: "你前面提到的“去中心化”概念好像最近也挺火的，具体指什么？",
					a: "“去中心化”的思想，主要来源于我们对社区产品的观察。和后来流行的社交网络由多个中心联结所不同，社区类产品的中心其实是社区本身，用户因为某些共通的属性或兴趣而团结在一起。但随着时间的流逝，这样的社区很容易像社会阶层固化一般，形成一个个掌握发言权与关注度的小圈子。也许他们可以在某些程度上，为社区发展提供助推作用，但对于其他更多人微言轻的普通用户来说，却是不平等的所在。不同于很多社区在培养意见领袖、召开线下见面会等来稳固“基本盘”，我们这种反潮流的理念和商业化价值其实是背道而驰的。我们也不知道这种形态的坚持还能有多久，只希望能在保持社区鲜活生命力与商业价值之间找到尽量平衡的道路吧。",
				},
				{
					q: "朵朵是一个严格的 timeline 型产品，你们怎么看现在非常流行的算法推荐呢？",
					a: "根据用户的偏好与标签，使用推荐算法，投用户所好，无疑会提高用户消费信息时的满意度，甚至广告的转化率（虽然我们并没有内容型广告），并显著提高用户时间，但这却是一件很危险的事情。用户被“投喂”怎样的信息流，这个算法机制，实质上是一个黑盒。即使用户明确知道它是由自己的偏好所决定的，也很容易让人陷入不自知也无法自拔的同温层。这与我们想要的公开、平等、高效等价值显然是背道而驰的。当然，虽然严格遵守时间倒序来编排我们的内容是我们当前的信条，但并不代表我们一味地排斥算法。毕竟算法也是技术，它并非真正中立，而是开发者的价值观体现。我们希望在未来和用户一起探索出兼顾用户体验与社会价值的算法，而不是被它奴役。",
				},
				{
					q: "那朵朵校友圈以后会往什么方向发展呢？",
					a: "我们从浙大起家，然后扩张到杭州二十来所高校，赢得了 40 多万用户，整个过程其实都是在摸索一种模式。不同于充斥着各种自编自导的假信息的表白墙、校园菌型的营销矩阵，我们希望以一种体面的姿态，茁壮生长，然后凭借着产品的优势赢得人心，接着散播全国。所以在横向上，我们会努力与其他有识之士合作开拓属于他们自己的独立校友圈，将我们的产品理念和运营模式传播出去；纵向上，我们会在坚持核心用户体验不受影响的同时，尝试探索出合理跨校沟通，真正连接所有大学生人心的模式，以实现我们的产品愿景。",
				},
			],
			showAllFAQ: false,
			pswpElement: null,
		};
	},
	mounted() {
		this.pswpElement = this.$refs["pswp"];
		this.createPartyMoreList();
		this.setPartyIntroIO();
		this.setSloganIO();
	},
	methods: {
		toMiniProgram() {
			// window.open("https://wxaurl.cn/bAL19h2oYqd");
			// window.open("weixin://dl/business/?t=8v4dwS9aM1o", "_blank");
			if (window.innerWidth > 1024) {
				return;
			}
			this.duoQrActive = !this.duoQrActive;
		},
		previewImage(src) {
			let images = this.historyList.reduce((acc, cur) => {
				let copy = cur.images.map((item) => ({
					src: item.src,
					msrc: item.src,
					w: item.w,
					h: item.h,
				}));
				acc.push(...copy);
				return acc;
			}, []);
			let options = {
				index: images.findIndex((item) => item.src === src), // start at first slide
				bgOpacity: 0.8,
				shareEl: false,
				tapToClose: true,
				showHideOpacity: true,
			};
			let gallery = new PhotoSwipe(this.pswpElement, PhotoSwipeUI_Default, images, options);
			gallery.init();
		},
		setPartyIntroIO() {
			let io = new IntersectionObserver(
				(entries) => {
					entries.forEach((item) => {
						if (item.intersectionRatio >= 0.8) {
							item.target.querySelectorAll(".duo-party-marker").forEach((dom) => {
								dom.classList.add("duo-party-marker-active");
							});
							console.log(".", item.target.childNodes);
							io.disconnect();
						}
					});
				},
				{
					threshold: 0.8,
				}
			);
			io.observe(this.$refs["partyIntro"]);
		},
		setSloganIO() {
			let io = new IntersectionObserver(
				(entries) => {
					entries.forEach((item) => {
						if (item.intersectionRatio >= 0.8) {
							document.querySelectorAll(".duo-rule-slogan").forEach((dom) => {
								dom.childNodes.forEach((childDom) => {
									childDom.classList.add("duo-rule-slogan-active");
								});
							});
							io.disconnect();
						}
					});
				},
				{ threshold: 0.8 }
			);
			io.observe(document.querySelector(".duo-rule-slogan"));
		},
		createPartyMoreList() {
			let nameList = [
				"安瓦尔",
				"佟湘玉",
				"草莓大福",
				"思噜八岐",
				"川崎菌",
				"修井盖的",
				"徐大聪明",
				"一只村草",
				"八倍镜",
				"云间星辰",
				"樱语冰凌",
				"叮咚买柴",
				"钟馗哥",
				"赚钱王道",
				"若春景明",
				"云上醉酒",
			];
			let tagMap = {
				"#3E94FC": "自习",
				"#FF6582": "电影",
				"#F6A50F": "聚餐",
				"#FF7D41": "拼车",
				"#FF655C": "拼单",
				"#00BB6F": "运动",
				"#B559FE": "游戏",
				"#02BCD4": "旅行",
			};
			let timeList = ["刚刚", "10分钟前", "4分钟前", "6分钟前", "5分钟前", "2分钟前"];
			let list = [];
			for (let i = 0; i < nameList.length; i++) {
				let name = nameList[i];
				let num = Math.floor(Math.random() * (8 - 4 + 1) + 4);
				let keys = Object.keys(tagMap);
				let randomKey = keys[Math.floor(Math.random() * keys.length)];
				let string = `${name} 等 ${num}人 ${
					timeList[Math.floor(Math.random() * timeList.length)]
				}攒成了${tagMap[randomKey]}局`;
				list.push({
					text: string,
					color: randomKey,
					avatar: i + 1,
				});
			}
			let moreList = [];
			moreList.push(list.slice(0, 5));
			moreList.push(list.slice(5, 10));
			moreList.push(list.slice(10, 15));
			moreList.map((item) => {
				item.push(...item.slice(0, 3));
			});
			this.partyMoreList = moreList;
			this.$nextTick(() => {
				this.$refs["avatar-0"].forEach((dom) => {
					dom.style.backgroundImage = `url(${require("../assets/avatar_random_" +
						dom.dataset.avatar +
						".png")})`;
				});
			});
		},
	},
};
</script>
<style scoped lang="scss">
img {
	object-fit: cover;
}
.anchor {
	position: absolute;
	top: calc(0px - var(--header-height));
	left: 0;
}
.home {
	color: #111;
	background-color: #f0f2f5;
	.head {
		height: 100vh;
		max-height: calc(100vh - var(--header-height));
		background-color: #ffffff;
		position: relative;
		.duo-c-body {
			align-items: center;
			width: 100%;
			margin-top: pxToRem(77);
			p {
				font-size: pxToRem(64);
				line-height: pxToRem(92);
				&:nth-child(1) {
					font-family: SourceHanSansCN-Light;
					font-weight: 300;
					letter-spacing: pxToRem(17);
					padding-left: pxToRem(14);
				}
				&:nth-child(2) {
					font-family: SourceHanSansCN-Heavy;
					//font-weight: bold;
				}
				&:nth-child(3) {
					font-size: pxToRem(30);
					line-height: pxToRem(50);
					font-family: SourceHanSansCN-Light;
					font-weight: 300;
					color: #444;
					padding: 0 pxToRem(16);
					margin-top: pxToRem(12);
				}
			}
			.duo-qr-btn {
				position: relative;
				width: pxToRem(348);
				height: pxToRem(90);
				border-radius: pxToRem(8);
				color: #fff;
				font-size: pxToRem(30);
				background-color: #5d81d0;
				margin-top: pxToRem(56);
				font-family: SourceHanSansCN-Medium;
				z-index: 10;
				.duo-qr-btn-arrow {
					width: pxToRem(10);
					display: block;
					margin-left: pxToRem(12);
					transform-origin: center center;
				}
				& > .duo-qr {
					position: absolute;
					top: pxToRem(84);
					left: 0;
					width: 100%;
					height: pxToRem(326);
					padding: pxToRem(6) pxToRem(24) pxToRem(24);
					border-radius: 0 0 pxToRem(8) pxToRem(8);
					background-color: #5d81d0;
					transform-origin: top center;
					transform: scaleY(0);
				}
				&.active {
					.duo-qr-btn-arrow {
						transform: rotate(90deg);
					}
					& > .duo-qr {
						display: block;
						transform: scaleY(1);
					}
				}
			}
		}
		.duo-btn-icon {
			position: absolute;
			display: block;
			&:nth-of-type(1) {
				width: pxToRem(86);
				height: pxToRem(86);
				top: pxToRem(260);
				left: pxToRem(-30);
			}
			&:nth-of-type(2) {
				width: pxToRem(60);
				height: pxToRem(60);
				top: pxToRem(-20);
				left: pxToRem(260);
			}
			&:nth-of-type(3) {
				width: pxToRem(76);
				height: pxToRem(76);
				top: pxToRem(20);
				right: pxToRem(60);
			}
			&:nth-of-type(4) {
				width: pxToRem(76);
				height: pxToRem(76);
				top: pxToRem(490);
				right: pxToRem(180);
			}
		}
		.duo-phone-wrap {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: calc(100vh - var(--header-height) - #{pxToRem(515)});
			overflow: hidden;
			@keyframes video-slide-in {
				from {
					transform: translateY(100vh);
				}
				to {
					transform: translateY(0);
				}
			}
			.video-wrap {
				width: pxToRem(450);
				height: pxToRem(948);
				margin: pxToRem(82) auto 0;
				animation: video-slide-in 1.2s cubic-bezier(0.19, 1, 0.22, 1);
				background: no-repeat center/100%
					url("https://img.uboxs.net/BHXqlYI0OdO9dOEXC3kB45J4U4n7SlzDctf2NXlH.png");
			}
			.video {
				width: 100%;
				display: block;
			}
			.phone-circle {
				position: absolute;
				display: block;
				top: pxToRem(145);
				left: pxToRem(12);
				width: pxToRem(720);
				z-index: 10;
			}
			.duo-emoji {
				position: absolute;
				display: block;
				z-index: 10;
				&.emoji-1 {
					width: pxToRem(72);
					top: pxToRem(311);
					left: pxToRem(52);
					transform: rotateY(180deg);
				}
				&.emoji-2 {
					width: pxToRem(96);
					top: pxToRem(207);
					right: pxToRem(13);
				}
				&.emoji-3 {
					width: pxToRem(140);
					top: pxToRem(560);
					right: pxToRem(117);
				}
			}
		}
	}
	.xiaoyouquan {
		width: 100%;
		min-height: 120vh;
		position: relative;
		background-color: #f7f7f7;
		overflow: hidden;
		.duo-xiaoyouquan-title {
			position: absolute;
			top: pxToRem(-40);
			right: pxToRem(-20);
			font-size: pxToRem(200);
			line-height: pxToRem(200);
			font-family: SourceHanSansCN-Bold;
			//font-weight: bold;
			color: rgba(0, 0, 0, 0.08);
			z-index: 1;
			letter-spacing: pxToRem(-20);
		}
	}
	.party {
		background-color: #f7f7f7;
		background-image: url("../assets/bg_cuanju_small.png");
		background-size: 100vw pxToRem(1393);
		background-repeat: no-repeat;
		.duo-party-title {
			position: absolute;
			top: pxToRem(-20);
			left: pxToRem(-20);
			font-size: pxToRem(200);
			line-height: pxToRem(200);
			font-family: SourceHanSansCN-Bold;
			//font-weight: bold;
			color: rgba(255, 255, 255, 0.12);
			z-index: 1;
			letter-spacing: pxToRem(-20);
		}
		.duo-party-intro {
			width: 100%;
			height: pxToRem(1333);
			padding-top: pxToRem(208);
			text-align: center;
			& > p {
				&:nth-of-type(1) {
					font-size: pxToRem(60);
					line-height: pxToRem(87);
					font-family: SourceHanSansCN-Heavy;
					//font-weight: bold;
					color: white;
					margin-bottom: pxToRem(30);
				}
				&:nth-of-type(2) {
					font-size: pxToRem(32);
					line-height: pxToRem(50);
					color: rgba(255, 255, 255, 0.8);
				}
			}
			& > .duo-party-marker {
				width: pxToRem(92);
				height: pxToRem(106);
				background-size: pxToRem(92) pxToRem(106);
				background-repeat: no-repeat;
				transform: scale(0);
				transform-origin: bottom center;
				transition: all 0.4s cubic-bezier(0.5, 1.58, 0, 1);
				@for $i from 1 through 5 {
					&:nth-of-type(#{$i}) {
						$url: "../assets/marker_cuanju_" + $i + ".png";
						background-image: url($url);
						@if $i == 1 {
							left: pxToRem(94);
							top: pxToRem(869);
						} @else if $i == 2 {
							left: pxToRem(531);
							top: pxToRem(1037);
						} @else if $i == 3 {
							left: pxToRem(217);
							top: pxToRem(1090);
						} @else if $i == 4 {
							left: pxToRem(465);
							top: pxToRem(778);
						} @else if $i == 5 {
							left: pxToRem(320);
							top: pxToRem(906);
						}
					}
				}
			}
			& > .duo-party-marker-active {
				transform: scale(1);
				@for $i from 1 through 5 {
					&:nth-of-type(#{$i}) {
						-webkit-transition-delay: ($i - 1) * 250ms;
					}
				}
			}
		}
		.duo-party-more {
			background-image: url("../assets/bg_cuanju_more_small.png");
			background-size: 100vw pxToRem(960);
			background-repeat: no-repeat;
			padding-top: pxToRem(90);
			height: pxToRem(960);
			& h2 {
				font-size: pxToRem(60);
				line-height: pxToRem(87);
				color: #333;
				font-family: SourceHanSansCN-Heavy;
				//font-weight: bold;
			}
			& > p {
				font-size: pxToRem(32);
				line-height: pxToRem(50);
				color: #555;
				margin-top: pxToRem(30);
			}
			& > .duo-party-more-banner {
				position: absolute;
				width: 100%;
				left: 0;
				top: pxToRem(533);
				& > .duo-party-more-banner-wrap {
					height: pxToRem(96);
					margin-bottom: pxToRem(30);
					@keyframes scroll {
						from {
							transform: translateX(0);
						}
						to {
							transform: translateX(pxToRem(-690 * 5));
						}
					}
					&:nth-of-type(1) {
						.duo-party-more-banner-box {
							animation: scroll 40s linear infinite;
						}
					}
					&:nth-of-type(2) {
						.duo-party-more-banner-box {
							animation: scroll 33s linear infinite reverse;
						}
					}
					&:nth-of-type(3) {
						.duo-party-more-banner-box {
							animation: scroll 40s linear infinite;
						}
					}
					& > .duo-party-more-banner-box {
						& > div {
							width: pxToRem(650);
							height: pxToRem(90);
							padding-left: pxToRem(28);
							margin-right: pxToRem(40);
							border: pxToRem(4) solid #444;
							border-radius: pxToRem(12);
							box-shadow: pxToRem(6) pxToRem(6) 0 0 #7f7d79;
							font-size: pxToRem(22);
							line-height: pxToRem(30);
							color: #333;
							font-weight: bold;
							& > div {
								width: pxToRem(60);
								height: pxToRem(60);
								border-radius: 50%;
								border: pxToRem(2) solid #404040;
								margin-left: pxToRem(-20);
								background-size: pxToRem(60) pxToRem(60);
								background-image: url("../assets/avatar_glass.png");
								&:first-of-type {
									margin-left: 0;
								}
								&:last-of-type {
									margin-right: pxToRem(12);
								}
							}
						}
					}
				}
			}
		}
	}
	.rule {
		.duo-rule-info {
			width: 100%;
			height: pxToRem(716);
			padding-top: pxToRem(270);
			background-color: #333333;
			.duo-rule-title {
				position: absolute;
				top: pxToRem(-20);
				left: 0;
				white-space: nowrap;
				font-size: pxToRem(170);
				line-height: pxToRem(170);
				font-family: SourceHanSansCN-Bold;
				//font-weight: bold;
				color: rgba(255, 255, 255, 0.12);
				letter-spacing: pxToRem(-20);
			}
			.duo-rule-text {
				max-width: pxToRem(510);
				font-size: pxToRem(22);
				line-height: pxToRem(32);
				font-family: SourceHanSansCN-Medium;
				color: #fff;
				text-align: left;
				& > a:nth-child(2) {
					color: #6c99f4;
					margin-top: pxToRem(30);
				}
			}
		}
		.duo-rule-slogan {
			width: 100%;
			height: pxToRem(528);
			padding-left: pxToRem(90);
			padding-top: pxToRem(80);
			font-size: pxToRem(120);
			font-family: SourceHanSansCN-Bold;
			font-weight: bold;
			line-height: pxToRem(174);
			background-color: white;
			color: #111;
			overflow: hidden;
			text-align: left;
			& > div {
				transform: translateY(-100%);
				opacity: 0;
			}
			& > .duo-rule-slogan-active {
				transform: translateY(0);
				opacity: 1;
				transition: all 1s ease-in-out;
				&:nth-child(2) > h2:nth-child(2):after {
					opacity: 1;
				}
			}
			& > div > h2:first-child {
				margin-right: pxToRem(90);
			}
			& > div:nth-child(2) > h2:nth-child(2) {
				position: relative;
				&:after {
					content: "。";
					position: absolute;
					opacity: 0;
					transition: opacity 1s ease-in-out;
					transition-delay: 0.8s;
				}
			}
		}
	}
	.history {
		background-color: white;
		overflow: hidden;
		.duo-history-title {
			position: absolute;
			width: 200vw;
			text-align: left;
			top: pxToRem(-28);
			left: pxToRem(10);
			font-size: pxToRem(200);
			line-height: pxToRem(200);
			letter-spacing: pxToRem(-20);
			font-family: SourceHanSansCN-Bold;
			color: rgba(0, 0, 0, 0.08);
		}
		.duo-history-wrap {
			padding-top: pxToRem(230);
			padding-left: pxToRem(172);
			.duo-history-container {
				background-image: linear-gradient(
					to bottom,
					rgba(0, 0, 0, 0.2) 30%,
					transparent 30%,
					transparent 100%
				);
				background-size: pxToRem(2) pxToRem(18);
				background-repeat: repeat-y;
				padding-left: pxToRem(37);
				& > .duo-history-item {
					position: relative;
					padding-bottom: pxToRem(80);
					& > h3 {
						position: relative;
						font-size: pxToRem(28);
						line-height: pxToRem(40);
						color: #444;
						font-family: SourceHanSansCN-Bold;
						//font-weight: bold;
						text-align: left;
						&::before {
							content: "";
							position: absolute;
							left: pxToRem(-55);
							width: pxToRem(36);
							height: pxToRem(36);
							border-radius: 50%;
							background: radial-gradient(
								#ec7a77 30%,
								rgba(#ec7a77, 0.2) 30%,
								rgba(#ec7a77, 0.2)
							);
						}
					}
					& > .duo-history-time {
						position: absolute;
						right: calc(100% + #{pxToRem(37 + 38)});
						top: pxToRem(-9);
						font-weight: bold;
						text-align: right;
						& > div {
							&:nth-child(1) {
								font-size: pxToRem(40);
								line-height: pxToRem(49);
								color: #ec7a77;
							}
							&:nth-child(2) {
								white-space: nowrap;
								color: #444;
								font-size: pxToRem(24);
								line-height: pxToRem(35);
								margin-top: pxToRem(16);
							}
						}
					}
					& > .duo-history-detail {
						max-width: pxToRem(1020);
						text-align: left;
						font-size: pxToRem(24);
						line-height: pxToRem(35);
						color: #777;
						font-family: SourceHanSansCN-Regular;
						margin-top: pxToRem(20);
					}
					& > .duo-history-pic {
						margin-top: pxToRem(30);
						width: 100%;
						overflow: scroll;
						& > div {
							width: auto;
							height: pxToRem(624);
							margin-right: pxToRem(13);
							&:last-child {
								margin-right: 0;
							}
							& > img {
								height: 100%;
								display: block;
							}
						}
					}
				}
			}
		}
	}
	.business {
		.duo-business {
			padding-top: pxToRem(80);
			color: #333;
			h2 {
				font-size: pxToRem(50);
				line-height: pxToRem(72);
				font-family: SourceHanSansCN-Heavy;
				//font-weight: bold;
				margin-bottom: pxToRem(40);
				&:nth-of-type(2) {
					margin-top: pxToRem(100);
				}
			}
			.duo-business-card {
				border: 1px solid #ddd;
				width: 100%;
				border-radius: pxToRem(12);
				padding: pxToRem(40) pxToRem(50);
				&-name {
					font-size: pxToRem(40);
					line-height: pxToRem(58);
					font-family: SourceHanSansCN-Heavy;
					//font-weight: bold;
					margin-bottom: pxToRem(36);
				}
				&-info {
					font-size: pxToRem(26);
					line-height: pxToRem(37);
					font-family: SourceHanSansCN-Regular;
					&:last-child {
						margin-top: pxToRem(20);
					}
				}
			}
			.duo-business-qr {
				width: pxToRem(160);
				height: pxToRem(160);
			}
			.duo-business-cooperation-pc {
				display: none;
			}
			.duo-business-cooperation-mobile {
				display: block;
				padding-bottom: pxToRem(56);
				.duo-business-cooperation-mobile-line {
					margin-bottom: pxToRem(24);
					& > img {
						display: block;
						width: pxToRem(208);
						height: pxToRem(79);
						margin-right: pxToRem(24);
						&:last-child {
							margin-right: 0;
						}
					}
				}
			}
		}
	}
	.product {
		padding-bottom: pxToRem(150);
		.duo-product-title {
			position: absolute;
			width: 200vw;
			top: pxToRem(-30);
			font-size: pxToRem(200);
			line-height: pxToRem(200);
			color: rgba(0, 0, 0, 0.08);
			font-family: SourceHanSansCN-Bold;
			letter-spacing: pxToRem(-20);
		}
		.duo-product {
			padding-top: pxToRem(200);
			color: #444;
			.duo-product-intro {
				text-align: left;
				font-size: pxToRem(26);
				line-height: pxToRem(40);
				font-family: SourceHanSansCN-Medium;
				font-weight: 500;
				margin-top: pxToRem(30);
				margin-bottom: pxToRem(60);
			}
			& > h3 {
				font-family: SourceHanSansCN-Bold;
				//font-weight: bold;
				font-size: pxToRem(28);
				line-height: pxToRem(40);
				margin-bottom: pxToRem(20);
			}
			.duo-product-card {
				padding: pxToRem(30);
				background-color: #f7f7f7;
				margin-bottom: pxToRem(20);
				text-align: left;
				& > h3 {
					font-size: pxToRem(28);
					line-height: pxToRem(40);
					font-family: SourceHanSansCN-Bold;
					//font-weight: bold;
					margin-bottom: pxToRem(20);
				}
				& > p {
					font-size: pxToRem(24);
					line-height: pxToRem(35);
					font-family: SourceHanSansCN-Regular;
					color: #777;
				}
			}
			.duo-product-btn {
				width: 100%;
				height: pxToRem(88);
				background-color: #dee4f2;
				color: #376cd7;
				border-radius: pxToRem(8);
				font-size: pxToRem(26);
				font-family: SourceHanSansCN-Medium;
				font-weight: 500;
			}
		}
	}
	.yangmaotuan {
		width: 100vw;
		aspect-ratio: 0.522;
		background: center/100% url(https://img.uboxs.net/886b60be185a4668b2d9cf46b124cbb1.png)
			no-repeat;
	}
}
@include screenMedia(md) {
	.home {
		.head {
			height: 52vw;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			.duo-c-body {
				margin-top: pxToRem(-70);
				margin-left: pxToRem(100);
				align-items: flex-start;
				p {
					font-size: pxToRem(60);
					line-height: pxToRem(87);
					max-width: pxToRem(666);
					&:nth-child(1) {
						padding: 0;
					}
					&:nth-child(3) {
						margin-top: pxToRem(20);
						font-size: pxToRem(24);
						line-height: pxToRem(35);
						padding: 0;
						text-align: left;
						max-width: pxToRem(610);
					}
				}
				.duo-qr-btn {
					width: pxToRem(276);
					height: pxToRem(60);
					font-size: pxToRem(20);
					margin-top: pxToRem(40);
					&:before {
						content: "微信扫码，";
					}
					.duo-qr-btn-arrow {
						width: pxToRem(8);
						margin-left: pxToRem(16);
					}
					&:hover {
						.duo-qr-btn-arrow {
							transform: rotate(90deg);
						}
						& > .duo-qr {
							display: block;
							transform: scaleY(1);
						}
					}
					& > .duo-qr {
						top: pxToRem(52);
						height: pxToRem(270);
						box-sizing: border-box;
						padding: pxToRem(8) pxToRem(14) pxToRem(14);
					}
					.qr-wrap {
						border-radius: pxToRem(8);
					}
					.qr-code {
						width: pxToRem(206);
						height: pxToRem(206);
						display: block;
					}
				}
			}
			.duo-btn-icon {
				&:nth-of-type(1) {
					top: pxToRem(388);
					left: pxToRem(201);
				}
				&:nth-of-type(2) {
					top: pxToRem(55);
					left: pxToRem(443);
				}
				&:nth-of-type(3) {
					top: pxToRem(137);
					left: pxToRem(764);
				}
				&:nth-of-type(4) {
					top: pxToRem(589);
					left: pxToRem(682);
				}
			}
			.duo-phone-wrap {
				height: 100vh;
				.video-wrap {
					width: pxToRem(500);
					height: pxToRem(1054);
					position: absolute;
					margin: 0;
					bottom: pxToRem(-300);
					right: pxToRem(360);
					background-image: url("https://img.uboxs.net/CIdw29WlpNGsi9lqlWakT5KANNq7Q9MZnSZShLzK.png");
				}
				.phone-circle {
					top: auto;
					left: auto;
					bottom: pxToRem(78);
					right: pxToRem(217);
					width: pxToRem(800);
				}
				.duo-emoji {
					position: absolute;
					display: block;
					z-index: 10;
					&.emoji-1 {
						width: pxToRem(80);
						top: auto;
						left: auto;
						bottom: pxToRem(458);
						right: pxToRem(900);
					}
					&.emoji-2 {
						width: pxToRem(110);
						top: auto;
						bottom: pxToRem(565);
						right: pxToRem(208);
					}
					&.emoji-3 {
						width: pxToRem(160);
						top: auto;
						bottom: pxToRem(125);
						right: pxToRem(318);
					}
				}
			}
		}
		.xiaoyouquan {
			min-height: auto;
			height: 110vh;
			max-height: 52vw;
			.duo-xiaoyouquan-title {
				font-size: pxToRem(320);
				line-height: pxToRem(320);
				letter-spacing: pxToRem(-60);
			}
		}
		.party {
			background-image: url("../assets/bg_cuanju_big.png");
			background-size: 100vw pxToRem(1057);
			.duo-party-title {
				font-size: pxToRem(320);
				line-height: pxToRem(320);
				margin-left: pxToRem(-20);
				letter-spacing: pxToRem(-60);
			}
			.duo-party-intro {
				height: pxToRem(1000);
				padding-top: pxToRem(380);
				text-align: left;
				& > p {
					&:nth-of-type(1) {
						font-size: pxToRem(60);
						line-height: pxToRem(87);
					}
					&:nth-of-type(2) {
						font-size: pxToRem(32);
						line-height: pxToRem(50);
						max-width: pxToRem(576);
					}
				}
				& > .duo-party-marker {
					@for $i from 1 through 5 {
						&:nth-of-type(#{$i}) {
							@if $i == 1 {
								left: pxToRem(1018);
								top: pxToRem(386);
							} @else if $i == 2 {
								left: pxToRem(1484);
								top: pxToRem(639);
							} @else if $i == 3 {
								left: pxToRem(1126);
								top: pxToRem(658);
							} @else if $i == 4 {
								left: pxToRem(1484);
								top: pxToRem(333);
							} @else if $i == 5 {
								left: pxToRem(1233);
								top: pxToRem(460);
							}
						}
					}
				}
			}
			.duo-party-more {
				background-image: url("../assets/bg_cuanju_more_big.png");
				background-size: 100vw pxToRem(852);
				height: pxToRem(852);
				padding-top: pxToRem(110);
				& h2 {
					font-size: pxToRem(60);
					line-height: pxToRem(87);
					&:nth-child(1)::after {
						content: "，";
					}
				}
				& > .duo-party-more-banner {
					top: pxToRem(396);
					& > .duo-party-more-banner-wrap {
						&:nth-of-type(1) {
							.duo-party-more-banner-box {
								animation: scroll 40s linear infinite;
							}
						}
						&:nth-of-type(2) {
							.duo-party-more-banner-box {
								animation: scroll 33s linear infinite reverse;
							}
						}
						&:nth-of-type(3) {
							.duo-party-more-banner-box {
								animation: scroll 40s linear infinite;
							}
						}
					}
				}
			}
		}
		.rule {
			.duo-rule-info {
				width: 50%;
				height: pxToRem(960);
				padding-top: pxToRem(432);
				.duo-rule-title {
					font-size: pxToRem(230);
					line-height: pxToRem(230);
					top: pxToRem(-26);
					right: pxToRem(-160);
					letter-spacing: pxToRem(-30);
				}
			}
			.duo-rule-slogan {
				width: 50%;
				height: pxToRem(960);
				padding-top: pxToRem(132);
				padding-left: pxToRem(280);
				& > div > h2:first-child {
					margin-right: 0;
				}
			}
		}
		.history {
			.duo-history-title {
				position: absolute;
				left: pxToRem(410);
				top: pxToRem(-46);
				font-size: pxToRem(320);
				line-height: pxToRem(320);
				letter-spacing: pxToRem(-40);
			}
			.duo-history-wrap {
				padding-top: pxToRem(339);
				padding-left: pxToRem(475);
				.duo-history-container {
					padding-left: pxToRem(57);
					& > .duo-history-item {
						& > h3 {
							font-size: pxToRem(24);
							line-height: pxToRem(35);
							&::before {
								left: calc(#{pxToRem(-16-57)});
							}
						}
						& > .duo-history-time {
							right: calc(100% + #{pxToRem(57 + 58)});
							& > div {
								&:nth-child(1) {
									& > i:nth-child(2):before {
										content: ".";
									}
								}
							}
						}
						& > .duo-history-detail {
							font-size: pxToRem(18);
							line-height: pxToRem(30);
						}
						& > .duo-history-pic {
							& > div {
								width: pxToRem(250);
								height: auto;
								margin-right: pxToRem(16);
								& > img {
									width: 100%;
									display: block;
								}
							}
						}
					}
				}
			}
		}
		.business {
			.duo-business {
				h2 {
					font-size: pxToRem(60);
					line-height: pxToRem(87);
					margin-bottom: pxToRem(60);
					&:nth-of-type(2) {
						margin-top: pxToRem(160);
					}
				}
				.duo-business-card {
					max-width: pxToRem(720);
				}
				.duo-business-qr {
					width: pxToRem(160);
					height: pxToRem(160);
				}
				.duo-business-cooperation-mobile {
					display: none;
				}
				.duo-business-cooperation-pc {
					display: block;
					padding-bottom: pxToRem(120);
					.duo-business-cooperation-pc-line {
						margin-bottom: pxToRem(30);
						& > img {
							display: block;
							width: pxToRem(275);
							height: pxToRem(105);
							margin-right: pxToRem(30);
							&:last-child {
								margin-right: 0;
							}
						}
					}
				}
			}
		}
		.product {
			.duo-product-title {
				top: pxToRem(-40);
				font-size: pxToRem(320);
				line-height: pxToRem(320);
				letter-spacing: pxToRem(-40);
			}
			.duo-product {
				padding-top: pxToRem(291);
				color: #444;
				.duo-product-intro {
					font-size: pxToRem(18);
					line-height: pxToRem(32);
					margin-bottom: pxToRem(80);
				}
				& > h3 {
					font-size: pxToRem(20);
					line-height: pxToRem(34);
				}
				.duo-product-card {
					padding: pxToRem(20);
					& > h3 {
						font-size: pxToRem(20);
						line-height: pxToRem(34);
						margin-bottom: pxToRem(8);
					}
					& > p {
						font-size: pxToRem(18);
						line-height: pxToRem(32);
					}
				}
				.duo-product-btn {
					width: pxToRem(344);
					height: pxToRem(78);
					border-radius: pxToRem(4);
					font-size: pxToRem(20);
					margin-top: pxToRem(40);
				}
			}
		}
		.yangmaotuan {
			aspect-ratio: 1.77;
			background-image: url(https://img.uboxs.net/07304e375548450b8f492f21f1abbd10.png);
		}
	}
}
</style>
