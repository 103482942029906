import util from "./util";

function setRem() {
	let clientWidth = document.documentElement.clientWidth;
	// document.documentElement.style.fontSize = (clientWidth / (clientWidth >= 1024 ? 1920 : 750)) * 100 + "px";
	document.documentElement.style.fontSize = clientWidth / 10 + "px";
}
setRem();

let oldDeviceStatus = util.isSmallDevice();
window.onresize = function () {
	if (util.isSmallDevice() !== oldDeviceStatus) {
		return window.location.reload();
	}
	setRem();
};
