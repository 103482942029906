var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"xiaoyouquan-container w-full h-full flex"},[_c('div',{staticClass:"cards-wrap absolute w-full",attrs:{"id":"cards-wrap"},on:{"click":function($event){$event.stopPropagation();return _vm.recoverInMobile.apply(null, arguments)}}},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"out-0",class:{
				'soft-hide': _vm.isSmallDevice && !item.focus && _vm.existFocusItem,
				'top-z-index': _vm.isSmallDevice && item.focus,
			},style:({
				top: _vm.pxToRem(item.top) + 'rem',
				left: _vm.pxToRem(item.left) + 'rem',
				transform: `translateX(${_vm.pxToRem(item.x)}rem) translateY(${_vm.pxToRem(
					item.y
				)}rem)`,
			}),on:{"click":function($event){$event.stopPropagation();return _vm.focusInMobile(index)},"mouseenter":function($event){return _vm.focusInPC(index)},"mouseleave":function($event){return _vm.recoverInPC(index)}}},[_c('div',{staticClass:"out-1 shadow card-pop",style:({ 'background-color': item.color })},[_c('div',{staticClass:"out-2",class:{ focus: item.focus },style:({
						height: _vm.isSmallDevice
							? `${_vm.pxToRem(
									item.focus ? item.focusHeight : item.defaultHeight
							  )}rem`
							: 'auto',
					})},[_c('div',{staticClass:"flex",staticStyle:{"align-items":"flex-start"}},[_c('img',{staticClass:"avatar shadow avatar-pop",attrs:{"src":item.avatar}}),_c('div',{staticClass:"flex-1 content-pop"},[_c('div',{staticClass:"nickname"},[_vm._v(_vm._s(item.nickname))]),_c('div',{staticClass:"content"},[_vm._v(_vm._s(item.content))])])]),_c('div',{staticClass:"tag"},[_vm._v(_vm._s(item.tag))]),_vm._l((item.commentList),function(comment,ii){return _c('div',{key:ii,staticClass:"comment-item flex"},[_c('img',{staticClass:"avatar avatar-small shadow avatar-pop",style:({ 'animation-delay': `${ii * 0.2 + 0.4}s` }),attrs:{"src":comment.avatar}}),_c('div',{staticClass:"flex-1 content-pop",style:({ 'animation-delay': `${ii * 0.2 + 0.65}s` })},[_c('div',{staticClass:"nickname content-small"},[_vm._v(_vm._s(comment.nickname))]),_c('div',{staticClass:"content content-small"},[_vm._v(_vm._s(comment.content))])])])})],2)])])}),0),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-container"},[_c('div',{staticClass:"title"},[_vm._v(" 全校级的大型朋友圈 "),_c('br'),_vm._v(" 本校内的迷你微博 ")]),_c('div',{staticClass:"desc"},[_vm._v(" 校友圈拥有朋友圈般和谐、互信的私域气氛。在这里你依然可以分享身边趣事，遍览校内新闻，还可以淘宝校友闲置，结识同志好友，但能量和效率却比朋友圈大上几十上百倍。 ")]),_c('div',{staticClass:"desc desc-2"},[_vm._v(" 校友圈提供了一个远小于全社会的舆论场，平衡了理性讨论与情感发泄的尺度，更为讨论本地公共事务，反映民生问题，提供了最初的阵地。 ")])])
}]

export { render, staticRenderFns }