export default {
	isMobile() {
		return navigator.userAgent.match(
			/(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
		);
	},
	isSmallDevice() {
		return window.innerWidth < 1024;
	},
	getBaseWidth() {
		return window.innerWidth < 1024 ? 750 : 1920;
	},
};
